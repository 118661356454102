import React, { useState, useEffect } from 'react';
import { Textfit } from 'react-textfit';
import './App.css';


const fontOptions = [
  {
    font: 'Merriweather',
    lineHeight: '0.65em',
    capLineHeight: '0.8em'
  },
  {
    font: 'Roboto Slab',
    lineHeight: '0.64em',
    capLineHeight: '0.8em'
  },
  {
    font: 'Bitter',
    lineHeight: '0.65em',
    capLineHeight: '0.8em'
  },
  {
    font: 'Volkhov',
    lineHeight: '0.645em',
    capLineHeight: '0.8em'
  }
]

function App() {
  const [pattern, setPattern] = useState(1);
  const [cap, setCap] = useState('A');
  const [text, setText] = useState('SAMPLE');
  const [fontCap, setFontCap] = useState(0);
  const [fontText, setFontText] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }, [])

  return (
    <div className="App">
      <div className="ld-demo">
        <div className="ld-large-letter-container">
          <div className="ld-large-letter" style={{fontFamily: fontOptions[fontCap].font, lineHeight: fontOptions[fontCap].capLineHeight}}>{cap}</div>
        </div>
        <img src={process.env.PUBLIC_URL + '/pattern_' + pattern + '.jpg'} />
        <Textfit className="demo-text" style={{fontFamily: fontOptions[fontText].font, lineHeight: fontOptions[fontText].lineHeight}} mode="single" max={300}>
          {text}
        </Textfit>
        <img src={process.env.PUBLIC_URL + '/pattern_' + pattern + '_b.jpg'} />
      </div>
      <div className="ld-form">
        <div className="ld-form-section">
          <div className="ld-flex-section">
            <div className="ld-flex-item ld-select" onClick={() => setPattern(1)}>
              <img src={process.env.PUBLIC_URL + '/pattern_1.jpg'} alt="pattern two bold vector" />
              <img src={process.env.PUBLIC_URL + '/pattern_1_b.jpg'} alt="pattern two bold vector" />
              <div className="ld-pattern-label">Pattern 1</div>
            </div>
            <div className="ld-flex-item ld-select" onClick={() => setPattern(2)}>
              <img src={process.env.PUBLIC_URL + '/pattern_2.jpg'} alt="pattern two bold vector" />
              <img src={process.env.PUBLIC_URL + '/pattern_2_b.jpg'} alt="pattern two bold vector" />
              <div className="ld-pattern-label">Pattern 2</div>
            </div>
          </div>
        </div>
        <div className="ld-form-section">
          <select className="ld-select" onChange={(e) => setCap(e.currentTarget.value)}>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="F">F</option>
            <option value="G">G</option>
            <option value="H">H</option>
            <option value="I">I</option>
            <option value="J">J</option>
            <option value="K">K</option>
            <option value="L">L</option>
            <option value="M">M</option>
            <option value="N">N</option>
            <option value="O">O</option>
            <option value="P">P</option>
            <option value="Q">Q</option>
            <option value="R">R</option>
            <option value="S">S</option>
            <option value="T">T</option>
            <option value="U">U</option>
            <option value="V">V</option>
            <option value="W">W</option>
            <option value="X">X</option>
            <option value="Y">Y</option>
            <option value="Z">Z</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
          </select>
          {renderFonts((e) => setFontCap(Number(e.currentTarget.value)))}
        </div>
        <div className="ld-form-section">
          <input className="ld-input" type="text" value={text} onChange={(e) => setText(e.currentTarget.value.toUpperCase())} maxLength="25" />
          {renderFonts((e) => setFontText(Number(e.currentTarget.value)))}
        </div>
        <div className="ld-button">Purchase</div>
      </div>
    </div>
  );
}

function renderFonts(onChange) {
  let results = [];

  for (let i = 0; i < fontOptions.length; i++) {
    const opt = fontOptions[i];

    results.push(<option key={'fot_' + i} style={{fontFamily: opt.font}} value={i}>{opt.font}</option>);
  }

  return (
    <select className="ld-select" onChange={onChange}>
      {results}
    </select>
  );
}

export default App;
